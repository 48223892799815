import CT from '../CT'
import i18n from 'i18next'

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
// Logos
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const appLogos = {
    neTimeLogo: 'netTime(T).svg',
    netTimeLogoLogIn: 'nt6Logo.svg',
    logoGrupoBgNettime6: 'logo_grupo_bgnettime6.svg',
}


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
// Notifications
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const notificationIcons = {
    info: 'notif_info.png',
    warn: 'notif_warning.png',
    error: 'notif_error.png',

    device: 'notif_device-info.png',
    deviceError: 'notif_device-error.png',

    accepted: 'notif_accepted.png',
    calendar: 'notif_calendar.png',
    comment: 'notif_comment.png',
    denied: 'notif_denied.png',
    document: 'notif_document.png',
    schedule: 'notif_horario.png',
    password: 'notif_password.png',
    plan: 'notif_planif.png'
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
// Menu
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const menuIcons = {
    eroski: 'eroski.png',
    Horario: 'clock_brand.svg',
    Config: 'setting_brand.svg',
    Accesos: 'accesos_brand.svg',
    Activadores: 'activador_brand.svg',
    Actualizar: 'refresh_brand.svg',
    Ajustes: 'sliders_brand.svg',
    Movimientos: 'moviments_brand.svg',
    Calendario: 'calendar_brand.svg',
    Jornada: 'jornadas_brand.svg',
    Incidencia: 'incidencias_brand.svg',
    Usuario: 'user_brand.svg',
    Terminal: 'terminales_brand.svg',
    Persona: 'users_brand.svg',
    AyudaOn: 'help_brand.svg',
    AyudaOff: 'help_off.svg',
    Resultado: 'consulta_brand.svg',
    Zona: 'zonas_brand.svg',
    FAcceso: 'f_accesos_brand.svg',
    Mensaje: 'terminal_msg_brand.svg',
    Actuacion: 'actuaciones_brand.svg',
    Aritmetico: 'aritmeticos_brand.svg',
    DefaultIcon: 'mark_warning_anomalia.svg',
    Notifications: 'notificaciones_brand.svg',
    monitoraccesos: 'monitor_accesos_brand.svg',
    monitorvisitas: 'monitor_visitas_brand.svg',
    configaccesos: 'gestor_accesos_brand.svg',
    monitoractividades: 'monitor_actividades_brand.svg',
    Visita: 'briefcase_brand.svg',
    Arbol: 'organigrama_brand.svg',
    Solicitudes: 'planificacion_brand.svg',
    monitorAct: 'monitor_actividades_brand.svg',
    PIN: 'password_brand.svg',
    Plantilla: 'plantilla_brand.svg',
    Add: 'add_brand.svg',
    newSolicitud: 'planificacion_new.svg',
    deactivate: 'desactivar_off.svg',
    reactivate: 'reactivar_brand.svg',
    warning: 'mark_warning_anomalia.svg',
    GestorTurnos: 'gestor_jornadas_brand.svg',
    Export: 'export_brand.svg',
    Sesiones: 'sesiones_activas_brand.svg',
    Card: 'card_brand.svg',
    Report: 'listado_brand.svg',
    Import: 'import_brand.svg',
    scheduled: 'program_task_brand.svg',
    documents: 'doc_brand.svg',
    workActivity: 'tareas_brand.svg',
    workOrder: 'orden_trab_brand.svg',
    eventTask: 'evento_brand.svg',
    about: 'about_brand.svg',
    gateway: 'outsideworks_brand.svg',
    idSenseConfig: 'idsense_brand.svg',
    a3: 'a3_enlace_brand.svg',
    ntMark: 'user_fingerprint.svg',
    accessView: 'zone-enter_on.svg',
    accessPerfil: 'perfil-access_brand.svg',

    //Right arrows (TODO: for RTL, search for correspondant left arrows, rotated 180º)
    rightArrow: 'right_arrow.svg',
    rightArrowWhite: 'right_arrow_white.svg',
    leftArrow: 'left_arrow.svg',
    leftArrowWhite: 'left_arrow_white.svg',

    // cForm menu options - refactor
    edit: 'edit_brand.svg',
    save: 'save_brand.svg',
    cancel: 'cancelar_brand.svg',
    validate: 'aceptar_brand.svg',
    refresh: 'refresh_brand.svg',
    printButton: 'printer_brand.svg',
    chartButton: 'chart_brand.svg',
    clockButton: 'clock_brand.svg',
    exportButton: 'export_brand.svg',
    searchButton: 'search_brand.svg',
    cancelButton: 'cancelar_brand.svg',

    //Accordion menu icons
    aceptar: 'aceptar_brand.svg',
    activators: 'activador_brand.svg',
    add: 'add_brand.svg',
    alarma: 'bell_brand.svg',
    calendar: 'calendar_brand.svg',
    chart: 'chart_brand.svg',
    clockings: 'moviments_brand.svg',
    comments: 'comments_brand.svg',
    config: 'sliders_brand.svg',
    copy: 'copy_brand.svg',
    defaultIcon: 'mark_warning_anomalia.svg',
    delete: 'bin_brand.svg',
    denegar: 'cancelar_brand.svg',
    down: 'down_brand.svg',
    enroll: 'user_fingerprint.svg',
    export: 'export_brand.svg',
    expulsar: 'zone-leave_on.svg',
    firmware: 'firmware_brand.svg',
    fullAllowed: 'aceptar_brand.svg',
    import: 'import_brand.svg',
    lector: 'lector_brand.svg',
    marcajes: 'clock_brand.svg',
    monitorTerminales: 'monitor_terminales_brand.svg',
    movimientos: 'moviments_brand.svg',
    newActivador: 'new-activador_brand.svg',
    newPlanActividad: 'new-plan-acts_brand.svg',
    newPlanOt: 'new-plan-ot_brand.svg',
    newTraspasos: 'traspaso_new_brand.svg',
    notAllowed: 'btn-denegar.svg',
    pause: 'pause_brand.svg',
    planActividad: 'tareas_brand.svg',
    planOt: 'orden_trab_brand.svg',
    presentes: 'accesos_brand.svg',
    print: 'printer_brand.svg',
    puerta: 'door_brand.svg',
    report: 'listado_brand.svg',
    resume: 'consulta_brand.svg',
    search: 'search_brand.svg',
    solicitudes: 'planificacion_brand.svg',
    start: 'play_brand.svg',
    subAllowed: 'aceptar_brand.svg',
    tarjeta: 'card_brand.svg',
    tasks: 'tareas_brand.svg',
    telecarga: 'terminal-telecarga_brand.svg',
    termactivar: 'terminal-activar_brand.svg',
    terminal: 'terminales_brand.svg',
    traspasos: 'traspaso_brand.svg',
    up: 'up_brand.svg',
    view: 'eye_brand.svg',
    viewOff: 'eye_off.svg',
    visitas: 'briefcase_brand.svg',
    stateVisit: 'state_visit.svg',
    vistaresumen: 'bar-chart_brand.svg',
    pieChart: 'pie-chart_brand.svg',
    DelFingers: 'fingerprint-delete_brand.svg',
    asteriskOn: 'asterisk_on.svg',  // JDS
    userPermisos: 'user-permisos_brand.svg',

}

const menuIconsTitle = {
    Horario: CT.i18n.t('Horario'),
    Config: CT.i18n.t('Configuración'),
    Accesos: CT.i18n.t('Accesos'),
    Activadores: CT.i18n.t('Activadores'),
    Actualizar: CT.i18n.t('Actualizar'),
    Ajustes: CT.i18n.t('Ajustes'),
    Movimientos: CT.i18n.t('Movimientos'),
    Calendario: CT.i18n.t('Calendario'),
    Jornada: CT.i18n.t('Jornada'),
    Incidencia: CT.i18n.t('Incidencia'),
    Usuario: CT.i18n.t('Usuario'),
    Terminal: CT.i18n.t('Terminal'),
    Persona: CT.i18n.t('Persona'),
    AyudaOn: CT.i18n.t('Ayuda activa'),
    AyudaOff: CT.i18n.t('Ayuda desactivada'),
    Resultado: CT.i18n.t('Resultado'),
    Zona: CT.i18n.t('Zona'),
    FAcceso: '',
    Mensaje: '',
    Actuacion: '',
    Aritmetico: '',
    DefaultIcon: '',
    Notifications: '',
    monitoraccesos: '',
    monitorvisitas: '',
    configaccesos: '',
    monitoractividades: '',
    Visita: '',
    Arbol: '',
    Solicitudes: '',
    monitorAct: '',
    PIN: '',
    Plantilla: '',
    Add: '',
    newSolicitud: '',
    deactivate: '',
    reactivate: '',
    warning: '',
    GestorTurnos: '',
    Export: '',
    Sesiones: '',
    Card: '',
    Report: '',
    Import: '',
    scheduled: '',
    documents: '',
    workActivity: '',
    workOrder: '',
    eventTask: '',
    about: '',
    gateway: '',
    idSenseConfig: '',
    a3: '',
    rightArrow: '',
    rightArrowWhite: '',
    leftArrow: '',
    leftArrowWhite: '',
    edit: '',
    save: '',
    cancel: '',
    validate: '',
    refresh: '',
    printButton: '',
    chartButton: '',
    clockButton: '',
    exportButton: '',
    searchButton: '',
    cancelButton: '',
    aceptar: '',
    activators: '',
    add: '',
    alarma: '',
    calendar: '',
    chart: '',
    clockings: '',
    comments: '',
    config: '',
    copy: '',
    defaultIcon: '',
    delete: '',
    denegar: '',
    down: '',
    enroll: '',
    export: '',
    expulsar: '',
    firmware: '',
    fullAllowed: '',
    import: '',
    lector: '',
    marcajes: '',
    monitorTerminales: '',
    movimientos: '',
    newActivador: '',
    newPlanActividad: '',
    newPlanOt: '',
    newTraspasos: '',
    notAllowed: '',
    pause: '',
    planActividad: '',
    planOt: '',
    presentes: '',
    print: '',
    puerta: '',
    report: '',
    resume: '',
    search: '',
    solicitudes: '',
    start: '',
    stateVisit: '',
    subAllowed: '',
    tarjeta: '',
    tasks: '',
    telecarga: '',
    termactivar: '',
    terminal: '',
    traspasos: '',
    up: '',
    view: '',
    visitas: '',
    vistaresumen: ''

}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
// Filters
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const filtersIcons = {
    usrOffFilters: 'add_brand.svg',
    usrOnFilters: 'filter_brand.svg',
    manualFilter: 'filter_on.svg',
    filtersOn: 'filter_on.svg',
    defaultFilterIcon: 'filter_brand.svg',
    defaultFilterDownIcon: 'filter_off.svg',
    filtersOff: 'filter_off.svg',
    filterPersonas: 'users_brand.svg',
    editFilterIcon: 'edit_brand.svg',

    //Filtros planificaciones
    planifAcp: 'aceptar_brand.svg',
    planifDen: 'cancelar_brand.svg',
    planifPen: 'planificacion_new.svg',
    anyActual: 'filter_on.svg',
    planifFutur: 'add_brand.svg',

    //Notificaciones
    warning: 'mark_warning_anomalia.svg',
    terminal: 'terminales_brand.svg',
    planificacion: 'planificacion_brand.svg'

}

const filtersIconsTitle = {
    usrOffFilters: '',
    usrOnFilters: '',
    manualFilter: '',
    filtersOn: '',
    filterPersona: '',
    defaultFilterIcon: '',
    defaultFilterDownIcon: '',
    editFilterIcon: '',
    planifAcp: CT.i18n.t('Planificación aceptada'),
    planifDen: CT.i18n.t('Planificación denegada'),
    planifPen: CT.i18n.t('Planificación pendiente'),
    anyActual: '',
    planifFutur: CT.i18n.t('Planificación futura'),
    warning: CT.i18n.t('Anomalías'),
    terminal: CT.i18n.t('Terminal'),
    planificacion: CT.i18n.t('Solicitud')
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
// Icons
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const appIcons = {
    activeElement: 'btn-aceptar.svg',
    noActiveElement: 'btn-denegar.svg',
    halfAllowed: 'btn-half_allowed.svg',
    halfDenied: 'btn-half_denied.svg',
    plusSquare: 'plus-square.svg',
    addItem: 'add_brand.svg',
    calendarOn: 'calendar_on.svg',
    calendarOff: 'calendar_off.svg',
    calendar: 'calendar_brand.svg',
    editar: 'edit_brand.svg',
    editarOn: 'edit_on.svg',
    editarOff: 'edit_off.svg',
    trashOff: 'bin_off.svg',
    trashOpened: 'bin_on.svg',
    trashClosed: 'bin_brand.svg',
    trash16: 'bin_brand.svg',
    periodEnd: 'fade_out_brand.svg',
    periodBegin: 'fade_in_brand.svg',
    periodEndSelect: 'fade_out_on.svg',
    periodBeginSelect: 'fade_in_on.svg',
    lector: 'lector_brand.svg',
    configure: 'user-permisos_brand.svg',
    docOn: 'doc_on.svg',
    doc: 'doc_brand.svg',
    docOff: 'doc_off.svg',
    visitas: 'briefcase_brand.svg',
    winClose: 'btn-close.svg',
    winMini: 'btn-mini.svg',
    winMaxi: 'btn-maxi.svg',
    winRest: 'btn-rest.svg',
    undo: 'undo_brand.svg',
    undoOff: 'undo_off.svg',
    camera: 'camera_brand.svg',
    cameraOn: 'camera_on.svg',
    cameraOff: 'camera_off.svg',
    aceptar: 'btn-aceptar.svg',
    denegar: 'btn-denegar.svg',
    warning: 'mark_warning_anomalia.svg',
    enroll: 'enroll_brand.svg',
    capturaOn: 'user_fingerprint.svg',
    capturaOff: 'user_no_fingerprint.svg',
    userPhoto: 'user_photo.svg',
    previewRep: 'preview_report.svg',
    refresh: 'refresh_brand.svg',
    refreshError: 'refresh_error.svg',
    card: 'card_brand.svg',
    asteriskOn: 'asterisk_on.svg',
    closeList: 'x-square.svg',
    activTarget: 'target_on.svg',
    transferButton: 'chevrons-right.svg',
    winGest: 'window-g_brand.svg',
    user: 'user_brand.svg',
    notifications: 'notificaciones_brand.svg',
    help: 'help_brand.svg',
    logIn: 'log-in_brand.svg',
    logOut: 'log-out_off.svg',
    logRed: 'log-out_red.svg',
    view: 'eye_brand.svg',
    viewOff: 'eye_off.svg',
    export: 'export_brand.svg',
    saveOn: 'save_brand.svg',
    saveOff: 'save_off.svg',
    headResults:'header_results.svg',
    about: 'about_brand.svg',
    expandSel: 'expand-select_brand.svg',

    //sso img-buttons
    ssoUser: 'sso-user_w.svg',
    ssoEmpl: 'sso-emple_w.svg',


    //Filter List mini panel
    aplicarSalir: 'btn-aceptar.svg',
    applyExit: 'btn-aceptar_w.svg',
    binClosed: 'bin_white.svg',

    //Accesss Monitor
    breadC: 'breadcrumb_grey.svg',
    rulesOn: 'rules_on.svg',
    rulesOff: 'rules_off.svg',
    presentsOn: 'presentes_on.svg',
    presentsOff: 'presentes_off.svg',
    zEnterOn: 'zone-enter_on.svg',
    zEnterOff: 'zone-enter_off.svg',
    zLeaveOn: 'zone-leave_on.svg',
    zLeaveOff: 'zone-leave_off.svg',
    acumulaOn: 'bar-chart_brand.svg',
    acumulaOff: 'bar-chart_off.svg',

    //Activities Monitor
    searchPlus: 'search_plus.svg',
    searchMinus: 'search_minus.svg',
    settings: 'sliders_brand.svg',
    searchPlusW: 'search_plus_white.svg',
    searchMinusW: 'search_minus_white.svg',

    //Collapsible accordion
    collapseLeft: 'chevron-left_white.svg',
    collapseRight : 'chevron-right_white.svg',

    //Movements
    clockMobile:'clock-mobile.svg',
    clockOffline: 'clock-offline.svg',
    clockUser: 'clock-user.svg',
    clockGeo: 'clock-geo.svg',
    clockVirtual: 'clock-virtual.svg',
    aceptadaSol: 'aceptada.svg',
    denegadaSol: 'denegada.svg',
    msg: 'msg_brand.svg',
    msgOn: 'msg_on.svg',
    endShiftsmark: 'mark_end_shifts.svg',
    movAnomalia: 'mark_warning_anomalia.svg',
    movAnomaliaCorrectable: 'mark_anomalia_marcaje.svg',
    movPlanAceptar: 'aceptada.svg',
    movPlanDenegar: 'denegada.svg',
    msgClocking: 'msg_clocking.svg',
    msgDay: 'msg_day.svg',
    history: 'historical_brand.svg',
    addActivators: 'plus-square_white.svg',


    //Movements - results
    svg_resAnomalia: 'mov-result-anomalia.svg',
    svg_resAritmetico: 'mov-result-aritmetico.svg',
    svg_resCalculo: 'mov-result-calculo.svg',
    svg_resIncidencia: 'mov-result-incidencia.svg',
    svg_resJornada: 'mov-result-jornada.svg',
    svg_resLector: 'mov-result-lector.svg',
    svg_resSistema: 'mov-result-sistema.svg',
    svg_resGrupo: 'mov-result-grupo.svg',
    svg_resActividad: 'mov-result-actividad.svg',
    svg_resOrdenDeTrabajo: 'mov-result-OT.svg',

    //CFItemMgr
    changeItemMgr: 'info_red.svg',

    //Calendar
    leftMonth: 'chevron-left.svg',
    rightMonth: 'chevron-right.svg',
    leftYear: 'chevrons-left.svg',
    rightYear: 'chevrons-right.svg',
    cicles: 'ciclos_brand.svg',
    addCicle: 'add_brand.svg',
    editCicle: 'edit_brand.svg',
    deleteCicle: 'bin_brand.svg',
    deleteSubcal: 'bin_brand.svg',
    addSubcal: 'add_brand.svg',

    //Cicles (modal window)
    dropRight: 'chevrons-right.svg',

    //Cierres
    defaultFilterIcon: 'filter_brand.svg',
    leftPage: 'chevron-left.svg',
    rightPage: 'chevron-right.svg',

    //Lectores de terminal
    Add: 'add_brand.svg',
    Delete: 'bin_brand.svg',

    //Container
    selColumn: 'columns_brand.svg',
    viewList: 'list-view_brand.svg',
    viewTree: 'tree-view_brand.svg',
    viewZones: "zonas_brand.svg",
    saveView: 'save_brand.svg',
    addFilter: 'add_brand.svg',
    viewFilters: 'filter_brand.svg',

    //Shifts
    rDisplace: 'r-displace.svg',
    rFlexible: 'r-flexible.svg',
    rMandatory: 'r-mandatory.svg',
    rPause: 'r-pause.svg',
    shiftNewRange: 'plus-square_white.svg',
    link: 'link.svg',
    unlink: 'unlink.svg',
    check: 'check-square.svg',
    uncheck: 'x-square.svg',
    inciNewRange: 'plus-square.svg',

    //Shifts - Calculus
    calcNew: 'plus-square.svg',
    calcNewRange: 'plus-square.svg',
    calcIcon: 'calculo.svg',
    calcDelete: 'bin_brand.svg',
    calcMinus: 'minus-circle_blue.svg',
    calcPlus: 'plus-circle_blue.svg',

    //Enroll
    f1InPlace: 'finger-1_in-place.svg',
    f1Placing: 'finger-1_placing.svg',
    f1Waiting: 'finger-1_waiting.svg',
    f2Reading: 'finger-2_reading.svg',
    f2Reading2: 'finger-2_reading-2.svg',
    f2ReadingAction: 'finger-2_reading-action.svg',
    f2ReadingOk: 'finger-2_reading-OK.svg',
    f2Waiting: 'finger-2_waiting.svg',
    f3Matching: 'finger-3_matching.svg',
    f3NoMatching: 'finger-3_no-matching.svg',
    f3Waiting: 'finger-3_waiting.svg',
    // Para las gráficas del portal
    periodoPortal: 'pattern2.png',

    //Grid / Tree component
    treeOpenBranches: 'plus-circle_grey.svg', // [+]
    treeCloseBranches: 'minus-circle_grey.svg', //[-]
    //Grid / Topbar - view selected items / view all items
    viewSelected: 'eye_brand.svg',
    viewAll: 'eye_brand.svg',

    // Tree icons
    departments: "departaments_brand.svg",
    zones: "zonas_brand.svg",
    terminal: "terminales_brand.svg",
    timetype: "incidencias_brand.svg",
    shift: "jornadas_brand.svg",
    reader: "lector_brand.svg",
    puerta: 'door_brand.svg',
    report: "listado_brand.svg",
    chart: 'chart_brand.svg',

    //Dashboard
    chartDashboard: 'chart_dashboard.svg',
    clockDashboard: 'clock_dashboard.svg',
    notiDashboard: 'notif_dashboard.svg',
    solicDashboard: 'plani_dashboard.svg',
    backDashboard: 'back_dashboard.svg',

    // Cálculo aritmético avanzado
    changeUndo: 'change-undo_brand.svg',
    changeRedo: 'change-redo_brand.svg',
    changeUndoOff: 'change-undo_off.svg',
    changeRedoOff: 'change-redo_off.svg',

    // Permisos
    explicitAllowed: 'btn-aceptar.svg',
    explicitDenied: 'btn-denegar.svg',
    deptAllowed: 'btn-half_allowed.svg',
    deptDenied: 'btn-half_denied.svg',
}


const appIconsTitle = {
    activeElement: '',
    noActiveElement: '',
    halfAllowed: '',
    halfDenied: '',
    plusSquare: CT.i18n.t('Nuevo!'),
    addItem: CT.i18n.t('Nuevo!'),
    calendarOn: '',
    calendarOff: '',
    editar: CT.i18n.t('Editar'),
    editarOn: '',
    editarOff: '',
    trashOpened: '',
    trashClosed: CT.i18n.t('Borrar'),
    trash16: '',
    periodEnd: CT.i18n.t('Final de periodo'),
    periodBegin: CT.i18n.t('Inicio de periodo'),
    periodEndSelect: CT.i18n.t('Seleccionado final de periodo'),
    periodBeginSelect: CT.i18n.t('Seleccionado inicio de periodo'),
    lector: '',
    configure: '',
    undo: CT.i18n.t('Deshacer'),
    undoOff: CT.i18n.t('Deshacer'),
    aceptar: CT.i18n.t('Aceptar'),
    denegar: CT.i18n.t('Denegar'),
    enroll: CT.i18n.t('Capturar huella'),
    capturaOn: CT.i18n.t('Huella capturada'),
    capturaOff: CT.i18n.t('Sin huella'),
    edit: CT.i18n.t('Editar'),
    documents: CT.i18n.t('Documentos'),
    saveOn: ' ',
    saveOff: ' ',

    //Filter List mini panel
    aplicarSalir: CT.i18n.t('Aceptar y cerrar'),
    applyExit: CT.i18n.t('Aceptar y cerrar'),
    binClosed: CT.i18n.t('Borrar'),

    //Activities Monitor
    searchPlus: CT.i18n.t('Aumentar Zoom'),
    searchMinus: CT.i18n.t('Disminuir Zoom'),
    settings: CT.i18n.t('Ajustes'),

    //Main
    user: CT.i18n.t('Usuario'),
    notifications: CT.i18n.t('Notificaciones'),
    logout: CT.i18n.t('Logout'),
    help: CT.i18n.t('Ayuda'),

    //Movements
    aceptadaSol: CT.i18n.t('Solicitud aceptada'),
    denegadaSol: CT.i18n.t('Solicitud denegada'),
    movPlanAceptar: CT.i18n.t('Aceptar solicitud'),
    movPlanDenegar: CT.i18n.t('Denegar solicitud'),
    clockMobile: CT.i18n.t('Marcaje móvil'),
    clockOffline: CT.i18n.t('Marcaje offline'),
    clockUser: CT.i18n.t('Usuario'),
    clockGeo: CT.i18n.t('Información de geolocalización disponible. Si dispone de conexión a internet, puede pulsar para visualizar el mapa de la zona.'),
    clockVirtual: CT.i18n.t('Marcaje virtual'),
    msgClocking: CT.i18n.t('Comentarios en algún marcaje'),
    msgDay: CT.i18n.t('Este día tiene comentarios'),
    history: CT.i18n.t('Historial de cambios'),
    comments: CT.i18n.t('Comentarios'),
    endShiftsmark: CT.i18n.t('Modifique el Final de la Jornada en Cambio de Jornada'),
    addActivators: CT.i18n.t('Añadir activador'),



    //Movements - Results
    svg_resAnomalia: CT.i18n.t('Anomalía'),
    svg_resAritmetico: CT.i18n.t('Aritmético'),
    svg_resCalculo: CT.i18n.t('Cálculo'),
    svg_resIncidencia: CT.i18n.t('Incidencia'),
    svg_resJornada:CT.i18n.t('Jornada'),
    svg_resLector: CT.i18n.t('Lector'),
    svg_resSistema: CT.i18n.t('Sistema'),
    svg_resGrupo: CT.i18n.t('Grupo'),
    svg_resActividad: CT.i18n.t('Actividad'),
    svg_resOrdenDeTrabajo: CT.i18n.t('Órden de trabajo'),

    //Calendar
    leftMonth: CT.i18n.t('Mes anterior'),
    rightMonth: CT.i18n.t('Mes siguiente'),
    leftYear: CT.i18n.t('Año anterior'),
    rightYear: CT.i18n.t('Año siguiente'),
    addCicle: CT.i18n.t('Ciclo nuevo'),
    editCicle: CT.i18n.t('Editar ciclo'),
    deleteCicle: CT.i18n.t('Borrar ciclo'),
    deleteSubcal: CT.i18n.t('Borrar subcalendario'),
    addSubcal: CT.i18n.t('Seleccionar subcalendario'),
    addGActuaciones: CT.i18n.t('Nuevo grupo de Actuaciones'),

    //Cierres
    leftPage: CT.i18n.t('Página anterior'),
    rightPage: CT.i18n.t('Página siguiente'),

    //Lectores de terminal
    Add: CT.i18n.t('Secuencia nueva'),
    Delete: CT.i18n.t('Borrar secuencia'),

    //Container
    selColumn: CT.i18n.t('Columnas'),
    viewList: CT.i18n.t('Lista'),
    viewTree: CT.i18n.t('Árbol'),
    viewZones: CT.i18n.t('Zonas'),
    saveView:CT.i18n.t('Guardar la vista actual'),
    viewFilters: CT.i18n.t('Filtros'),
    addFilter: CT.i18n.t('Añadir filtro'),


    //Shifts
    shiftNewRange: CT.i18n.t('Franja nueva'),
    link: CT.i18n.t('Vinculada a la franja maestra'),
    unlink: CT.i18n.t('No vinculada a la franja maestra'),
    inciNewRange: CT.i18n.t('Franja nueva'),
    check: CT.i18n.t('Incidencia activa'),
    uncheck: CT.i18n.t('Incidencia no activa'),
    rDisplace: CT.i18n.t('Desplazar franjas'),
    rFlexible: CT.i18n.t('Añadir un rango de horas flexibles'),
    rMandatory: CT.i18n.t('Añadir un rango de horas obligadas'),
    rPause: CT.i18n.t('Añadir pausa'),

    //Shifts - Calculus
    calcNew: CT.i18n.t('Cálculo nuevo'),
    calcNewRange: CT.i18n.t('Rango nuevo'),
    calcIcon: CT.i18n.t('Cálculo'),
    calcDelete: CT.i18n.t('Borrar cálculo'),

    //Grid / Tree component
    treeOpenBranches: CT.i18n.t('Abrir  árbol'), // [+]
    treeCloseBranches: CT.i18n.t('Cerrar árbol'), //[-]
    //Grid / Topbar - view selected items / view all items
    viewSelected: CT.i18n.t('Ver seleccionados'),
    viewAll: CT.i18n.t('Ver todos'),

    // Cálculo aritmético avanzado
    changeUndo: CT.i18n.t('Deshacer'),
    changeRedo: CT.i18n.t('Rehacer'),
    changeUndoOff: CT.i18n.t('No se puede deshacer'),
    changeRedoOff: CT.i18n.t('No se puede rehacer'),

    // Permisos
    explicitAllowed: CT.i18n.t('Vista autorizada: Explícita'),
    explicitDenied: CT.i18n.t('Vista denegada: Explícita'),
    deptAllowed: CT.i18n.t('Vista autorizada:  Por departamento'),
    deptDenied: CT.i18n.t('Vista denegada: Por departamento'),
}


export default {

    getLogo: (logoName) => {
        if (!appLogos[logoName]) return ''
        return CT.LOGOS_PATH + appLogos[logoName]
    },


    getMenuIcon: (icon) => {
        if (!menuIcons[icon]) return ''
        return CT.ICONS_SVG + menuIcons[icon]
    },
    getMenuTitle: (icon) => {
        if (!menuIconsTitle[icon]) return ''
        return CT.ICONS_SVG + menuIconsTitle[icon]
    },


    getFilterIcon: (icon) => {
        if (!filtersIcons[icon]) return ''
        return CT.ICONS_SVG + filtersIcons[icon]
    },
    getFilterTitle: (icon) => {
        if (!filtersIconsTitle[icon]) return ''
        return i18n.t(filtersIconsTitle[icon])

    },

    getIcon: (icon) => {
        if (!appIcons[icon]) return ''
        return CT.ICONS_SVG + appIcons[icon]
    },
    getTitle: (icon) => {
        if (!appIconsTitle[icon]) return ''
        return i18n.t(appIconsTitle[icon])
    },

    getNoticationIcon: (icon) => {
        if (!notificationIcons[icon]) return ''
        return CT.ICON_NOTIFICATION + notificationIcons[icon]
    }

}
