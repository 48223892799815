
import i18n from 'i18next'
import stream from 'mithril/stream'
import moment from "moment"
import SDMgr from "./SDLib/SDMgr"
import utils from './utils'
import * as R from 'ramda'
import m from "mithril"

// -------------------------------------------------------------------------------------------
// BUILD_MODE is a placeHolder that webpack replaces with the string "production" or "development"
// depending on the 'mode' defined in the "scripts" section of "package.json"

const isDevBuild = () =>  {
    try { return BUILD_MODE === "development"} catch (err) {return true} // Testing finds BUILD_MODE not defined
}
// IS_DEBUG can be set to "true" by sending host:port/#!/debug/ url
let IS_DEBUG = stream(false)

const isProductionBuild = () => {
    try { return BUILD_MODE === "production"} catch (err) {return false}
}


let isUpdateLicense = stream(false)

let isLoading = stream(false)  // if true, spinner will block app

let isRebooting = stream(false)

let sessionIntervalCheck = stream()

let browserVersionData = {
    disableBrowserCheck: false,
    isOutdated: false,
    browser: '',
    type: {},
    version: '',
    label: ''
}

const copyright = () => 'netTime6 copyright SPEC, S.A. 2020 All rights reserved'

// -------------------------------------------------------------------------------------------
// CONSTANTS
const CELL_MIN_WIDTH = 120 //Cell minimal width
const CELL_RENDERER_WIDTH = 40 //Cell of type "renderer" with

//NT6 predefined container Names (... some of them)
const CONTNAMES = {
    CALENDAR: "Calendario",
    EMPLOYEE: "Persona",
    TIMETYPE: "Incidencia",
    ACTIVITY: "WorkActivity",
    EVENT_TASK: 'EventTask',
    WORK_ORDER_PLANI: 'PlaniOT',
    SHIFT: "Jornada",
    WO: "WorkOrder",
    READER: "Lector",
    TREE_ORG: "Arbol",
    TREE_ZONES: "Zona",
    CICLO: 'Ciclo',
    CALENDARIO_ACTUACIONES: 'CalendarioActuaciones',
    GACTUACION: 'GActuacion',
    ACTUACION: 'Actuacion',
    INCIDENCIA_FUTURA: 'IncidenciaFutura',
    TIPO_INCIDENCIA: 'TipoIncidencia',
    SETTINGS: 'Settings',
    FACCESO: 'FAcceso',
    PERFIL_ACCESOS: 'PerfilAccesos',
    SYSTEM_RESULT: 'SystemResult',
    NOTIFICATIONS: 'AlarmInstance',
    ALARM_DEF: "AlarmDefs",
    ZONA: 'Zona',
    PUERTA: 'Puerta',
    ANOMALIA_RESULT: 'AnomaliaResult',
    TIPO_ANOMALIA: 'TipoAnomalia',
    PLANTILLA_FILTRO: 'PlantillaFiltro',
    RESULTADO: 'Resultado',
    FIELD_VIEW: 'FieldView',
    ARITMETICO: 'Aritmetico',
    TRASPASO_MANUAL: 'TraspasoManual',
    EVENT_TYPE: 'EventType',
    CHART: 'Chart',
    TERMINAL: 'Terminal',
    DETECTED_TERMINAL: 'DetectedTerminal',
    VISITA: 'Visita',
    ARBOL: 'Arbol',
    CONTAINER: 'Container',
    ACTIVADORES: 'Activadores',
    USO_ACTIVADORES: 'UsoActivadores',
    WORK_ACTIVITY_PLANI: 'PlaniActividad',
    CONTAINER_INFOTASK: 'ContainerInfoTask',
    PERMISSIONS_TEMPLATES: 'PermissionsTemplates',
    REPORT: 'Report',
    RESULTS_TEMPLATE: 'PlantillaResultados'
}

// -------------------------------------------------------------------------------------------
// Settings
let _settings = {firstDate: 2004, lastDate: 2030} //Default values
let _index = {}
const storeSettings = (newSettings) => _settings = newSettings
const storeIndex = (newIndex) => _index = newIndex
const getFirstDate = () => _settings.firstDate
const getLastDate = () => _settings.lastDate
const getHelpPage = () => _settings.helpPage
//anonymous, employee, user, admin
const isAdmin = () => _settings.rol === "Admin"
const isUser = () => _settings.rol === "User"
const isEmployee = () => _settings.rol === "Persona"
const hasActivities = () => !!(_settings.taskConfig & 0x20)
const hasWorkOrders = () => !!(_settings.taskConfig & 0x10)

const isQALicense = () => {
    if (_index.isQA !== undefined) {
        return _index.isQA
    }
    _index.isQA = !!(_index && _index['qaMessage'] && _index['qaMessage'].length > 0)
    return _index.isQA
}


const isRequiredLicenseUpdate = () => {
    let requiredUpdate = false
    if (_index && _index.hasOwnProperty('updateVer')) {
        requiredUpdate = _index.updateVer
    }
    return requiredUpdate
}

const runningOutdatedBrowser = () => (!browserVersionData.disableBrowserCheck && browserVersionData.isOutdated)

const getQAMessage = () => _index.isQA ? _index['qaMessage']  : ''

const getCanWriteCard = () => _index.canWriteCard ? !!_index.canWriteCard : false

let _allSettings
let _SpecDriver
let _TODOs = []
let pendingTODOs = stream(false)

const storeAllSettings = (data) => {
    _allSettings = data.settings

    _SpecDriver = _allSettings.reduce((filtered, setting) => {
        if (setting.section === 'specdriver') {
            filtered[setting.name] = {
                defaultValue: setting.defaultValue,
                value: setting.value,
                type: setting.type
            }
        }
        return filtered
    }, {})

    SDMgr.setSettings(_SpecDriver)
}


const getSettingValue = (settingName) => {
    let rawValue = R.find((setting) => setting.name === settingName)(_allSettings)
    if (rawValue) {
        switch (rawValue.type) {
            case "Boolean": return typeof (rawValue.value) === "string" && rawValue.value.toLowerCase() === "true"
            case "Int32": return parseInt(rawValue.value, 10)
            default: return  rawValue.value
        }
    }
}

const setTODOs = (data) => {
    if (data && Array.isArray(data) && data.length > 0) {
        _TODOs = data.map((elem) => {
            if (elem.date) {
                try {
                    elem.date = moment(elem.date).format('YYYYMMDD')
                } catch (e) {
                    console.error(e)
                }
            }
            return elem
        })
        pendingTODOs(true)
    }
}

const nextTODO = () => {
    let todo
    if (_TODOs && _TODOs.length > 0) {
        todo = _TODOs.shift()
        if (_TODOs.length === 0) pendingTODOs(false)
    }
    return todo
}

const getTODOs = () => (_TODOs)

const getSpecDriverCfg = () => _SpecDriver

const getUserName = () => _settings.username

const getShowSupervisor = () => _index.showSupervisorOnLogin


const CELL_WIDTH_FACTOR_MULTIPLY = 10

//given a cellDef (definition of a cell), grants that cellDef is initialized with a "cwStm" stream with default value
//If no renderer, no original width, no percentWidth is provided, the stream is set to "0" and later, inside "arrangeColsWidth" a redistribution is made
const getCellWidth = (cellDef, fromViewDefinition) => {
    //If column has an itemRenderer, applies an empiric fixed width (but only when cell definition doesn't come from view definition, in that case view was fixed by the user and must be respected)
    if (!fromViewDefinition) {
        const rendererName = cellDef['renderer'] || cellDef['ir']
        switch (rendererName) {
            case 'BooleanIR':
            case 'EnabledIR':
            case 'IncidenciasIR':
            case 'JornadasIR':
            case 'CierresIR':
            case 'IntervalIR':
            case 'DeleteIR':
            case 'ActionIR':
            case 'TreePermissionIR':
            case 'IconIR':
                if (!cellDef.avoidFixedWidth) cellDef.cwStm = stream(CELL_RENDERER_WIDTH)
                return cellDef.cwStm
        }
    }

    //Sometimes column width is specified by "percentWidth"
    if (cellDef.percentWidth) return stream(utils.ensurePercent(cellDef.percentWidth))

    //If cwStm already exists, preserve this value (because header is recreated on new columns or when the window is minimized / restored)0
    if (cellDef.cwStm) return cellDef.cwStm

    //Otherwise, explore "width". Original "width" values (flex version) maybe refer to chars? => *10 is applied!
    let origW = !isNaN(cellDef.width) && cellDef.width > 0 ? CELL_WIDTH_FACTOR_MULTIPLY * cellDef.width : 0 //Set to "0" to be recalculated later inside "arrangeColsWidth"
    return stream(origW)
}

// From server: numeric codes are used to specify each field align
//  0 => center, 1 => justify, 2 => left, 3 => notSet, 4 => right
//
// Internally, the codes used are 'left' 'center' and 'right'
//
// This function returns the corresponding className for both encodings
const getCellAlign = (alignValue) => {
    let align = ''
    switch (alignValue) {
        case 2:
        case 'left': align = 'txt-left'
            break
        case 4:
        case 'right': align = 'txt-right'
            break
        case 0:
        case 'center': align = 'centered'
            break
    }
    return align
}



//From: https://github.com/Chalarangelo/30-seconds-of-code#chainasync
const isArray = val => !!val && Array.isArray(val);
const toLower = (val) => {
    if (typeof val === 'string') return val.toLowerCase()
    return ''
}

// Forces return value to be incoming "s" as string, without accents and to lower case,
// ready to be used in a search comparison
// See: https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript/37511463#37511463
const unAccentAndLowerCase = (s) => {
    let s1 = s + '' //ensure s1 is a string
    s1 = s1.normalize("NFD").replace(/[\u0300-\u036f]/g, "") //normalizes turns é into e + ´ . Then replace removes the ´
    return s1.toLowerCase()
}


//Copy all elements in 'org' array into 'dest' array starting at position 'pos'
const copyArray = (dest, pos, org) => {
    if (org && dest && (pos <= dest.length || pos === 0)){
        for (let i = 0; i < org.length; i++) {
            dest.splice(pos + i, 0, org[i])
        }
    }
}


//Returns a copy of an object and its internals.
// NOTE: looses any Javascript property that has no equivalent type in JSON, like Function or Infinity.
// Any property that’s assigned to undefined will simply be ignored by JSON.stringify, causing them to be missed on the cloned object.
// Also, some objects are simply converted to strings, like Date objects for example (also, not taking into account the timezone and defaulting to UTC), Set, Map and many others:
// SEE: https://flaviocopes.com/how-to-clone-javascript-object/
//** CONCLUSION ** : It is usable for copying objects coming from NT6 server (because only contains, arrays, objects, numbers, strings...), but another method would be used if more complex data is required to be deeply cloned
const cloneDeep = (obj) => obj ? JSON.parse(JSON.stringify(obj)) : undefined //https://stackoverflow.com/questions/122102/what-is-the-most-efficient-way-to-deep-clone-an-object-in-javascript

const toggle = (v) => {if (typeof v === 'function') v(!v())}

//Formats a given moment mDate as "DD-MM-YYYY"
const COMPACT_DATE_STR = "DD-MM-YYYY"
const COMPACT_DATE_TIME_STR = "DD-MM-YYYY HH:mm:ss"
const FILTER_COMPACT_DATE_STR = "YYYY-MM-DD"
const LARGE_DATE_STR = "D MMMM YYYY"

const isNullDate = (mDate) => {
    if (moment.isMoment(mDate)) return mDate.year() === 1 //NetTime sets 1/1/1 as a NULL date
    return true
}
const getNullDate = () => moment().year(1).month(0).date(1)
const getSystemBeginDate = () => moment().year(_settings.firstDate).month(0).date(1).startOf('day')
const getSystemEndDate = () => moment().year(_settings.lastDate).month(11).date(31).endOf('day')
const getMomentDate = (strDate, withTime) => moment(strDate, withTime ? COMPACT_DATE_TIME_STR : COMPACT_DATE_STR)
const getMomentComplete = (strDateTime) => moment(new Date(strDateTime))
const now = () => moment()

//Returns 'true' if validity is empty (there is no validity info) OR there are some validity periods defined
//inside 'validity' and at least one of them contains today at day level
const validityContainsToday = (validity) => {
    if (validity !== undefined && isArray(validity) && validity.length > 0) {
        let somePeriodContainsToday = false
        let today = moment()
        R.forEach((period) => {
            let start = moment(period.StartDate || period.start)
            let end = moment(period.EndDate || period.end)
            if (today.isBetween(start, end,  'day', '[]')) somePeriodContainsToday = true// [] is for inclusivity
        })(validity)
        return somePeriodContainsToday
    }
    return true
}


const formatCompactDate = (mDate, withTime) => {
    if (isNullDate(mDate)) return ''
    if (mDate.isValid()) return mDate.format(withTime ? COMPACT_DATE_TIME_STR : COMPACT_DATE_STR)
    console.log("ERROR: formatCompactDate: not a valid moment")
}

const formatLargeDate = (mDate) => {
    if (isNullDate(mDate)) return ''
    if (mDate.isValid()) return mDate.format(LARGE_DATE_STR)
    console.log("ERROR: formatCompactDate: not a valid moment")
}


const formatDateForServer = (mDate) => {
    if (isNullDate(mDate)) return ''
    if (mDate.isValid()) return mDate.format(FILTER_COMPACT_DATE_STR)
    console.log("ERROR: formatDateForServer: not a valid moment")
}

//Given a date in a compact format, ex "20190103" (used as a parameter inside an url, ex: url to open movs externally),
// returns a string representing a ISO js standard date, ex: "2019-02-27T23:00:00.00Z"
// The returned string can be parsed by moment to represent the original compact date
const convertDateFromCompact = (compactDate) => {
    let dt = moment(compactDate, "YYYYMMDD")
    return dt.toISOString()
}

//Given a val that can be a number or a string representing a number or a % or can have "px" suffix,
//returns a value suitable to be injected as style, ex "23px" or "100%"
const formatPixelsForStyle = (val) => {
    if (typeof val === 'string' && val.indexOf('%') === val.length -1) return val
    let n = parseInt(val, 10)
    return isNaN(n) ? val : n + 'px'
}

const unformatPixelsFromStyle = (sVal) => {
    let posPx = sVal.indexOf('px')
    if (posPx >= 0) {
        let subNum = sVal.substr(0, posPx)
        return parseInt(subNum, 10)
    }
    return 0
}


//Given a numeric timeTypeType value, based on a static table, returns the corresponding description name
const getTimeTypeTypeName = (ttValue) => {
    switch (ttValue) {
        case 0:  return i18n.t("Productivas en el centro") // ProductivasEnElCentro = 0, "009933", "Productivas en el centro"
        case 1:  return i18n.t("Productivas fuera del centro") // ProductivasFueraDelCentro = 1, "3399FF", "Productivas fuera del centro"
        case 2:  return i18n.t("Otras actividades en el centro") // NoProductivasEnElCentro = 2, "FFDD00", "Otras actividades en el centro"
        case 3:  return i18n.t("Otras actividades fuera del centro") // NoProductivasFueraDelCentro = 3, "FFDD00", "Otras actividades fuera del centro"
        case 4:  return i18n.t("Ausencias justificadas") // AusenciasJustificadas = 4, "FF9900", "Ausencias justificadas"
    }
}

const redirectToError = () => {
    if (!isUpdateLicense()) {
        console.log('Redirecting to error page...')
        m.route.set('/error')
    }
}

const redirectToLogin = () => {
    let hash = window.location.hash
    let pos = hash.indexOf('!')
    let route = (pos >= 0) ? hash.substr(pos + 1) : ''

    if (!isRebooting()) {

        let href = ''

        if (_settings && _settings.logoutPage && _settings.logoutPage !== '/') {
            href = _settings.logoutPage
        } else {
            if (route) {
                href = `login.html#!${route}`
            } else {
                href = 'login.html'
            }
        }

        location.href = href
    }
}

//log with a special color
let allowMarkedLog = false //Must be false in production!!
const log = (msg, obj) =>  IS_DEBUG() || allowMarkedLog ? console.log(`%c ${msg}${obj !== undefined ? JSON.stringify(obj) : ''}`, 'background: #0000DD; color: #fff') : undefined
const log2 = (msg, obj) => IS_DEBUG() || allowMarkedLog ? console.log(`%c ${msg}${obj !== undefined ? JSON.stringify(obj) : ''}`, 'background: #00DDDD; color: #000') : undefined
const log3 = (msg, obj) => IS_DEBUG() || allowMarkedLog ? console.log(`%c ${msg}${obj !== undefined ? JSON.stringify(obj) : ''}`, 'background: #DDEE00; color: #000') : undefined
const log4 = (msg, obj) => IS_DEBUG() || allowMarkedLog ? console.log(`%c ${msg}${obj !== undefined ? JSON.stringify(obj) : ''}`, 'background: #44DD44; color: #000') : undefined
const log5 = (msg, obj) => IS_DEBUG() || allowMarkedLog ? console.log(`%c ${msg}${obj !== undefined ? JSON.stringify(obj) : ''}`, 'background: #DD0000; color: #fff') : undefined
const warning = console.warn
const error = console.error

//Functional loop: invokes fn N times accumulating every output in an array and returns the array
const repeat = (times, fn) => {
    let output = [], i
    for (i = 0; i < times; i++) {
        output.push(fn(i))
    }
    return output
}


const forceRedraw = (reason) =>{
    if (IS_DEBUG() && !window._global__TEST_MODE__ ) console.log("|| ForceRedraw ||: ", reason)
    m.redraw()
}

//A redraw is required when "isLoading" changes, specially if the change is derived from a failed request
isLoading.map((isLoading) => {
    forceRedraw("isLoading changed to " + isLoading)
})

//Containers where any filter button is visible (cannot select nor create new filters)
const mustHideFilterButtons = (contName) => {
    switch (contName) {
        case CONTNAMES.SHIFT:
        case CONTNAMES.ARITMETICO:
        case CONTNAMES.ACTIVADORES:
        case CONTNAMES.DETECTED_TERMINAL:
            return true
    }
    return false
}

//Containers where fixed filters are visible and selectable but cannot create new filters
const mustShowOnlyFixedFilters = (contName) => contName === CONTNAMES.TIMETYPE || contName === CONTNAMES.VISITA

//Special case of containers in HybridGrid that has different behavior: searchers, filters and counting applies to branches
const isSpecialTreeContainer = (contName) =>  contName === CONTNAMES.ARBOL || contName === CONTNAMES.ZONA

//Returns the className required for menu buttons depending on enabled / disabled state
const getClassNameForMenuButton = (enabled) =>  enabled ? 'btn-mmenu btn-main-menu' : 'btn-mmenu btn-mmenu-disabled btn-main-menu'


//If a div content requires ellipsis, set the 'title' property (tooltip) to "text"
const updateTitleIfEllipsisActive = (elem, text) => {
    if (elem && elem.offsetWidth < elem.scrollWidth) {
        elem.title = text
    } else {
        elem.title = ""
    }
}

// When ValuesListPanel is shown, a list of elements (Depertamentos, Jornada, Calendario, etc) is shown
// By default this list is multi-selectable, but in some cases (depending on the operator) it can be
// specified the type of combination for all the selected elements
const operatorAdmitsCombination = (op) => {
    return op === "Permission" || op === "!Permission"
}

// Given 2 expressions, combines them if both have value or takes the second one over the first one
// depending on which one has value
const combineFilterExp = (filterExp, internalFilterExp) => {
    let combinedFilterExp = filterExp
    if (internalFilterExp && typeof internalFilterExp == "function") {
        let sInternalFilterExp = internalFilterExp()
        if (sInternalFilterExp && sInternalFilterExp.length > 0) {
            if (filterExp === undefined || filterExp === "") {
                combinedFilterExp = sInternalFilterExp
            } else {
                combinedFilterExp = filterExp + " && " + sInternalFilterExp
            }
        }
    }
    return combinedFilterExp
}


// Returns a string with attributes "entity-id" & "entity-pos" ready to be injected in a mithril tag  to facilitate e2e testing
const getRowE2EAttributes = (rData, keyField, isNode = false) => {

    // console.log('reMerge')

    if (rData) {
        let key = keyField || "id"
        let strId = rData[key] !== undefined ? `[entity-id=${rData[key]}]` : '' // draw entity-id="val" only if value for the key is found
        let strPos = rData["entity-pos"] !== undefined ? `[entity-pos=${rData["entity-pos"]}]` : '' // idem for entity-pos
        return strId + strPos + `[node="${!!isNode}"]`
    }
    return ''
}

//GG: grid used inside "ExtraGrid" components to replace <table>. Every style in grid.scss is prefixed by gg-
const GG = {
    HEADER_HEIGHT: 22, //Grid header height
    TOPBAR_HEIGHT: 35, //Grid TopBar height
    CELL_MIN_WIDTH: CELL_MIN_WIDTH,
    DEFAULT_ROW_HEIGHT: 24,
    //Number of rows to limit height of combo list
    COMBO_LIST_MAX_ROWS: 8,
    //** LLP -- 1/7/2020 => NEW CRITERIA: Limit value to show searchBox is set to the numer of combo rows  (8 , 8)
    COMBO_LIST_REQUIRED_ROWS_TO_SHOW_SEARCHBOX: 8 //Number of rows from witch search box in a combo is shown
}

const getToolbarHeight = () =>  GG.TOPBAR_HEIGHT

const getComboListDefaultHeight = () =>  GG.DEFAULT_ROW_HEIGHT * GG.COMBO_LIST_MAX_ROWS + getToolbarHeight()


export default {

    getSettings: () => _settings,

    // Fake translation
    i18n: { t: (t) => (t) },

    copyright,
    browserVersionData,

    forceRedraw,
    isLoading,
    isRebooting,
    isUpdateLicense,
    sessionIntervalCheck,
    storeSettings,
    getSettingValue,
    storeIndex,
    storeAllSettings,
    getUserName,
    getShowSupervisor,

    setTODOs,
    getTODOs,
    nextTODO,
    pendingTODOs,

    CELL_WIDTH_FACTOR_MULTIPLY,
    getCellWidth,
    getCellAlign,
    getToolbarHeight,
    getSpecDriverCfg,
    toggle,
    isArray,
    copyArray,
    toLower,
    unAccentAndLowerCase,
    cloneDeep,
    redirectToLogin,
    redirectToError,
    repeat,
    mapIndexed: R.addIndex(R.map),

    //settings values
    isAdmin,
    isUser,
    isEmployee,
    isQALicense,
    isRequiredLicenseUpdate,
    runningOutdatedBrowser,
    hasActivities,
    hasWorkOrders,
    getFirstDate,
    getLastDate,
    getHelpPage,
    getQAMessage,
    getCanWriteCard,
    // Bundle mode (production or dev)
    isProductionBuild,
    isDevBuild,
    //date
    isNullDate,
    getNullDate,
    getMomentDate,
    getMomentComplete,
    now,
    getSystemBeginDate,
    getSystemEndDate,
    formatCompactDate,
    formatLargeDate,
    formatDateForServer,
    convertDateFromCompact,
    validityContainsToday,
    COMPACT_DATE_STR,
    FILTER_COMPACT_DATE_STR,
    //other
    formatPixelsForStyle,
    unformatPixelsFromStyle,
    getTimeTypeTypeName,
    mustHideFilterButtons,
    mustShowOnlyFixedFilters,
    isSpecialTreeContainer,
    getClassNameForMenuButton,
    updateTitleIfEllipsisActive,
    operatorAdmitsCombination,
    combineFilterExp,


    getRowE2EAttributes,

    //debug
    IS_DEBUG,
    log,
    log2,
    log3,
    log4,
    log5,
    warning,
    error,


    ICONS_SVG: './assets/svg/',
    ICON_NOTIFICATION: './assets/img/notifications/',
    //ICONS_APP_16_PATH: './assets/icons/n5/16/',
    //ICONS_MENU_PATH: './assets/img/menu/',
    //ICONS_FILTERS_PATH: './assets/img/filters/',
    LOGOS_PATH: './assets/img/logos/',

    MINIMAL_YEAR: 1900, //For datePicker, the minimal possible year

    MS_DELAY_SET_FOCUS: 1,  //ms to wait for invocking 'focus()' on a domElement that requires focus
    MS_DELAY_EXTRACOMBO_ANIM: 100, //duration of extraCombo fade animation

    GRID_N: 12, //CForm grid number of columns (12)

    //H
    H_MAIN_MENU: 32,
    GRID_MIN_COL_WIDTH: 10, //minimal width for a column in the grid when it is resized
    GRID_ROOT_DEFAULT_HEIGHT: 600, //Used to init grid height when testing

    //Dimensions for different parts of the app
    DIM: {
        DOCS_POPUP_W: 700,
        DOCS_POPUP_H: 400,
        MOD_CFG_ACCESS_W: 1100,
        MOD_CFG_ACCESS_H: 680,
        MOD_MOV_W: 1200,
        MOD_MOV_H: 600,
        MOD_ACTUACIONES_W: 1030,
        MOD_ACTUACIONES_H: 630,
        DATEPICKER_SHEET_HEIGHT: 190 //measured value (used to relocate datePicker when it doesn't fit at vertical)
    },

    //Style values
    SELECTED_ROW_COLOR: utils.getStyleValue('.row-selected', 'background-color'),
    UNDEFINED_COLOR: "CCCCCC", //When something has color property but there is no value defined, ex "FAcceso"

    //Simple constants
    CLOCKING: "CLOCKING",
    DAY: "DAY",

    //CWin action codes
    CWIN: {
        ACTION: {
            ENABLE: 'ENABLE',
            CLOSE: 'CLOSE',
            MINIMIZE: 'MINIMIZE',
            MAXIMIZE: 'MAXIMIZE',
            RESTORE: 'RESTORE'
        }
    },
    //Constants comming from netTime related to Commands (ex menuOption.Commands array)
    COMMANDS: {
        OPEN_CONTAINER: 'OPEN_CONTAINER',
        OPEN_URL: 'OPEN_URL',
        OPEN_CFORM: 'OPEN_CFORM',
        FILTER_EDITOR: 'FILTER_EDITOR',
        ARITHMETIC_EDITOR: 'ARITHMETIC_EDITOR',
        SAT: 'SAT',
        DEBUG_WIN: 'DEBUG_WIN',
        EXEC_ACTION: 'EXEC_ACTION',
        OPEN_MODULE: 'OPEN_MODULE',
        CHANGE_STATE: 'CHANGE_STATE'
    },

    CFORM: {
        LABEL_NC: 2, //Number of columns of cForm Labels
        TOTAL_NC: 12,
        PCENT_COL: 8.33333333333,
        DEFAULT_STATE_NAME: 'default'
    },

    //Possible working modes: grid as a fullcontainer, or multiple selection tree, or combo..
   GRIDMODE: {
        COMBO: 'COMBO',
        TREE_MULTISEL: 'TREE_MULTISEL',
        TREE_GRID: 'TREE_GRID',
        FULL_VIEW: 'FULL_VIEW',
        HYBRID_GRID: 'HYBRID_GRID'
    },
    GRID_SELECTION_MODE: {
        NORMAL: 'NORMAL',
        APPEND: 'APPEND',
        NOT_SELECTABLE: 'NOT_SELECTABLE'
    },

    HYBRID_COLUMN: 'treeColData', //Do not change name, view definition coming from server may have this value

    GG, //Grid constants
    getComboListDefaultHeight,

    EVENTS: {
        DRAG_START: 'DRAG_START',
        DROP: 'DROP',
        COLUMN_RESIZED: 'COLUMN_RESIZED',
        COLUMN_STOP_RESIZE: 'COLUMN_STOP_RESIZE',
        USER_COLUMN_RESIZED: 'USER_COLUMN_RESIZED',
        WIN_RESIZED: 'WIN_RESIZED',
        WIN_MOVED: 'WIN_MOVED',
        WIN_MAXIMIZED: 'WIN_MAXIMIZED'
    },

    DATAMODE: {
        DATASOURCE: 'DATASOURCE',
        CONTAINER: 'CONTAINER',
        CONTAINER_CUSTOM: 'CONTAINER_CUSTOM', //Same as "CONTAINER", but container definition is not loaded. Instead, columns, field order, etc must be defined as Grid properties
        STATIC: 'STATIC',
        TREE: 'TREE',
        HYBRID: 'HYBRID',
        TREE_CHILDREN: 'TREE_CHILDREN'
    },

    TREE: {
        EMPTY_NODE_NAME: i18n.t('(Sin agrupación)')
    },

    KEY: {
        BACK_SPACE: 8,
        TAB: 9,
        DELETE: 46,
        ENTER: 13,
        SPACE: 32,
        LEFT: 37,
        RIGHT: 39,
        UP: 38,
        DOWN: 48,
        REPAG: 33,
        AVPAG: 34,
        CTRL: 17,
        ESCAPE: 27,
        //Combination of keys
        CTRL_Z: 'CTRL_Z'
    },

    ACTION_RESULT_TYPE: {
        MESSAGE: 1,
        ERROR: 2,
        FORM_WIN: 3,
        CONFIRM_REQUIRED: 4,
        CLOSE_WINDOW: 5,
        SUCCESS: 6,
        OPEN_MODULE: 7,
        SUCCESS_DELETE: 8,
        OPEN_CONTAINER: 9,
        EXEC_ACTION: 10,
        OPEN_URL: 15,
        ASYNC_PRINT: 16
    },

    //Overlay layers: some components can have overlay, and inserted over another overlayed components (ex: CDatePicker inside a IntervalIR). Then, a layer different of 0 is required
    OVERLAY_LAYER_1: 1,
    OVERLAY_LAYER_FULLCONT_FILTERS: 3,
    OVERLAY_LAYER_FULLCONT_SAVEVIEWBTN: 4,
    OVERLAY_LAYER_FULLCONT_COL_SELECTOR: 5,
    OVERLAY_LAYER_CIERRES_IR: 6,
    OVERLAY_LAYER_CLAYER_LIST_ITEM: 7,
    OVERLAY_LAYER_INTERVAL_IR: 8,               //IMPORTANT: INTERVAL_IR layer > OVERLAY_LAYER
    OVERLAY_LAYER_DATEPICKER: 9,                //IMPORTANT: DATEPICKER layer  > INTERVAL_IR layer
    OVERLAY_LAYER_COMBOS: 10,                   //IMPORTANT: COMPOS layer  > DATEPICKER layer

    OVERLAY_LAYER_BKG_COLOR: 'rgba(0, 0, 0, 0.2)',

    TOOLTIP_TIME: 400,

    //Different modes of working with CIERRES component
    CIERRES_MODE: {
        EDITING_ARITMETIC: 'EDITING_ARITMETIC',
        EDITING_USER_EXCEPTIONS: 'EDITING_USER_EXCEPTIONS'
    },


    INPUT_MASK_TYPE: {
        DATE: 'DATE',  //input mask of: DD/MM/YYYY
        PHONE: 'PHONE',
        HOURS_MINUTES: 'HOURS_MINUTES',
        INTERVAL_3_X_24_H: 'INTERVAL_3_X_24_H',
        DECIMAL: 'DECIMAL',
        INTEGER: 'INTEGER'
    },

    CONTNAMES,

    //NT6 predefined container id's
    //**** DO NOT RENAME!! This are upper-case names of real container names!!
    CONTAINER_ID: {
        CICLO: 1,
        CALENDARIO: 5,
        JORNADA: 8,
        INCIDENCIA: 9,
        USUARIO: 11,
        ZONA: 12,
        TERMINAL: 13,
        PERSONA: 14,
        MESSAGE: 15,
        FACCESO: 16,
        PLANTILLARESULTADOS: 17,   //**** DO NOT RENAME!! This are upper-case names of real container names!!
        PLANTILLAFILTRO: 18,
        ARBOL: 22,
        RESULTADO: 23,
        ARITMETICO: 24,
        GACUACION: 25,
        CALENDARIOACTUACIONES: 26,    //**** DO NOT RENAME!! This are upper-case names of real container names!!
        INCIDENCIAFUTURA: 27,
        CONTAINER: 28,
        SETTINGS: 29,
        TIPOINCIDENCIA: 30,
        TIPOANOMALIA: 32,
        CONTYPEMENSAJE: 33,
        TIPORESULTADO: 34,
        LECTOR: 35,
        TARJETA: 36,
        METODOSALTO: 37,
        MENU: 38,
        SEXO: 39,
        CONTYPE: 40,
        ALARMA: 41,
        MODULE: 42,
        EVENTTYPE: 43,
        FIELDS: 45,
        SYSTEMRESULT: 46,    //**** DO NOT RENAME!! This are upper-case names of real container names!!
        ANOMALIARESULT: 47,
        FIELDVIEW: 49,
        SESSIONS: 50,
        TERMINALSTATUS: 51,      //**** DO NOT RENAME!! This are upper-case names of real container names!!
        TIMEZONE: 52,
        PUERTA: 53,
        DEVICE: 54,
        DEVICECONNECTOR: 55,
        DEVICETYPE: 56,
        DETECTEDTERMINAL: 57,
        ACTUACION: 58,
        MESSAGEDIRECTION: 59,
        TRASPASOMANUAL: 60,
        ORIGENTRASPASO: 61,
        HORIZONTALALIGN: 62,
        CHART: 63,
        CUSTOM: 64,
        ALARMDEFS: 65,
        ESTADOVISITA: 68,
        EXPORTTEMPLATE: 69,
        ALARMINSTANCE: 70,
        EVENTACTION: 71,
        A3EQUIPO: 74,
        CONTADORESA3EQUIPO: 75,
        PERMISSIONSTEMPLATES: 76,  //**** DO NOT RENAME!! This are upper-case names of real container names!!
        ACTIVADORES: 77,
        USOACTIVADORES: 78,
        SCHEDULEDTASKS: 79,
        WORKACTIVITY: 80,
        WORKORDER: 81,
        EVENTTASK: 82,
        PLANIACTIVIDAD: 83,
        PLANIOT: 84,
        CONTAINERINFOTASK: 85,
        PERFILACCESOS: 100,
        VISITA: 101,
        TARJETAVISITA: 102,
        NTMARK: 103,
        REPORT: 120,
        CUSTOMERS: 200
    },


    // NT6 RESULT types codes. It means, result source.
    // Usually TYPE_RESULT and ID_RESULT attributes points the real entity.

    RESULT_TYPE: {
        INCIDENCIA: 1,
        SISTEMA: 2,
        CALCULO: 3,
        JORNADA: 4,
        ANOMALIA: 5,
        ARITMETICO: 6,
        LECTOR: 7,
        GRUPO_DE_LECTORES: 8,
        ACTIVIDAD: 9,
        ORDEN_DE_TRABAJO: 10,
        EVENTO_AREA: 11
    },

    // Source result base values. Always accumulated value of both properties, exceed and effective.
    RESULT_HOURS: {
        NONE: 0,
        POSITIVE_EFFECTIVE: 1,
        POSITIVE_EXCEEDED: 2,
        NEGATIVE_EFFECTIVE: 5,
        NEGATIVE_EXCEEDED: 10
    },


    SHIFT_BAR: {
        ROW_HEIGHT: 25,                 // Important! -> All rows must be equal height
        BIT_ARRAY_LENGTH: 4320,
        HEADER_HEIGHT: 70,
        RULER_MARGIN: 5,
        FINAL_MARGIN: 35,
        ROW_WIDTH: 1512,                // 1080 // 2160 // 4320
        PIXEL_FIX: .5,
        TOTAL_HOURS: 72,
        BG_COLOR: '#fafafa',
        DEFAULT_COLOR: '#00a09b',
        RULER_FLEX_COLOR: '#8bc34a',
        RULER_MAND_COLOR: '#795548',
        UGLY_YELLOW: 'FFDD00',
        BEAUTY_YELLOW: 'F8CE00',
        BREAK_COLOR: '#ffffff',
        ARROW_COLOR: '#f8e600',
        TRANSPARENCY_PERCENT: .35
    },

    TEST_ROW_WIDTH_DEFAULT: 783 //Requied by CMovs testing
}
